/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

body {
  /* position: fixed; */
  /* display: block; */
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  overflow: auto;
  margin: 0;
  padding: 0;
}
canvas {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: -1;
  position: fixed;
  top: 0;
}
/* .stretch {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  
  overflow: auto;
} */
#crawl-container {
  padding: 0;
  margin: 0;
  perspective: calc(100vh * 0.4);
  height: 1000px;
  /* max-width: 800px; */
  z-index: 1;
  position: fixed;
  overflow: hidden;
  top: 1;
}
#crawl {
  color: #f5c91c;
  position: absolute;
  width: 110%;
  left: -5%;
  bottom: -5%;
  height: 200%;
  overflow: hidden;
  /* max-width: 800px; */

  transform: rotate3d(1, 0, 0, 45deg);
  transform-origin: 50% 100%;

  mask-image: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.66),
    rgba(0, 0, 0, 1)
  );

  -webkit-mask-image: -webkit-linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.66),
    rgba(0, 0, 0, 1)
  );
}

#crawl-content {
  font-family: Arial, Helvetica, sans-serif;
  font-size: calc(100vw * 0.074);
  /* letter-spacing: 0.09em; */
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
}

#crawl p {
  /* text-align: justify; */
  width: 100%;
  /* margin: 0 0 1.25em 0; */
  line-height: 1.05em;
  font-size: 0.8em;
}

#crawl h1 {
  font-size: 1em;
  margin: 0 0 0.3em 0;
}

#crawl h2 {
  font-size: 1.5em;
  margin: 0 0 0.7em 0;
}

#crawl h1,
#crawl h2 {
  text-align: center;
}

#crawl .svg-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-bottom: 1.4em;
  margin-bottom: 2.5em;
}

#crawl .svg-logo-subheader {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-top: 2em;
  margin-bottom: 1em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.scrollable {
  height: calc(100vw * 9.5);
  max-width: 800px;
  position: relative;
}

.App-link {
  color: #61dafb;
}

.about-subcontainer {
  width: 80%;
  /* will need to take this out later */
  border-width: 10px 10px 10px 10px;
  border-color: #f5c91c;
  border-style: solid;
  border-radius: 10px;
  margin-left: 0.5em;
  margin-bottom: 1em;
}

@media (max-width: 490px) {
  .about-subcontainer svg {
    width: 10em;
    height: auto;
  }
}

.languages-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.language svg {
  width: 100px;
  height: 100px;
}

@media (max-width: 490px) {
  .language svg {
    width: 60px;
    height: 60px;
  }
}

/* .about-subcontainer .about-btn {
  height: 30%;
  width: 30%;
} */

.about-btn-container {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-btn-container .about-btn {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: 90%;
  height: 20%;
  border-width: 5px 5px 5px 5px;
  color: #f5c91c;
  border-color: #f5c91c;
  border-style: solid;
  border-radius: 10px;
  background-color: transparent;
  font-size: 0.5em;
  cursor: pointer;
}

.about-btn:hover {
  background-color: #f5c91c;
  color: black;
}

.tech-view-container {
  width: 80%;
  height: 80%;

  background-color: #545557;
  position: fixed;
  left: 10%;
  top: 1em;
  border-radius: 10px;
  overflow-y: auto;
}

.tech-view-container .skill-view-container {
  margin: 0.5em 0.25em 0.5em 0.25em;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skill-btn-container {
  font-size: 0.5em;
  width: 7%;
  height: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.skill-btn-container a svg {
  width: 100%;
  text-decoration: none;
  color: #f5c91c;
}

.skill-view-container svg {
  width: 20%;
}

.skill-view-container .skill-content {
  width: 50%;
  font-size: 0.5em;
}

.tech-view-container button {
  width: 20%;
  height: 10%;
  background-color: #990000;
  border-radius: 50px;
  border-color: #990000;
  font-size: 0.3em;
  margin: 20px;
  color: white;
}

.tech-view-container button:hover {
  background-color: white;
  color: #990000;
}

.algorithms-container .latest-algorithm {
  font-size: 0.6em;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.algorithm-btns {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: space-between;
}

.algorithm-btns .solution-btn {
  width: 40%;
  height: 40%;
  background-color: transparent;
  border-color: #f5c91c;
  border-style: solid;
  border-radius: 10px;
  border-width: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution-btn a {
  text-decoration: none;
  font-size: 0.7em;
  text-align: center;
  color: #f5c91c;
}

.solution-btn:hover {
  background-color: #f5c91c;
}

.solution-btn a:hover {
  color: black;
}

.algorithms-view-container {
  width: 80%;
  height: 80%;
  position: fixed;
  left: 10%;
  top: 1em;
  border-radius: 10px;
  overflow-y: auto;
  border-color: #f5c91c;
  border-style: solid;
  border-radius: 10px;
  border-width: 5px;
}

.algorithm {
  font-size: 0.6em;
  display: flex;
  margin: 0.5em;
  justify-content: space-between;
}

.algorithm .challenge-title {
  width: 40%;
  margin-right: 1em;
}

.challenge-btns {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-around;
}

.challenge-btns .challenge-solution {
  /* width: 120px;
  height: 40px; */
  width: 40%;
  height: auto;
  background-color: transparent;
  border-color: #f5c91c;
  border-style: solid;
  border-radius: 10px;
  border-width: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.challenge-solution a {
  text-decoration: none;
  font-size: 0.7em;
  text-align: center;
  color: #f5c91c;
}

.challenge-solution:hover {
  background-color: #f5c91c;
}

.challenge-solution a:hover {
  color: black;
}

.algorithms-view-container button {
  width: 20%;
  height: 10%;
  background-color: #990000;
  border-radius: 50px;
  border-color: #990000;
  font-size: 0.3em;
  margin: 20px;
  color: white;
}

.algorithms-view-container button:hover {
  background-color: white;
  color: #990000;
}

.project-content {
  width: 80%;
  /* will need to take this out later */
  border-width: 10px 10px 10px 10px;
  border-color: #f5c91c;
  border-style: solid;
  border-radius: 10px;
  margin-left: 0.5em;
  margin-bottom: 1em;
}

.project-content .image-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.project-content .image-container img {
  width: 60%;
  height: auto;
}

.project-btn-container {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-btn-container .project-btn {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: 90%;
  height: 20%;
  border-width: 5px 5px 5px 5px;
  color: #f5c91c;
  border-color: #f5c91c;
  border-style: solid;
  border-radius: 10px;
  background-color: transparent;
  font-size: 0.5em;
  cursor: pointer;
}

.project-btn-div a {
  color: #f5c91c;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  padding: 7px 7px 2px 7px;
  text-align: center;
}

.project-btn svg {
  width: 20%;
  height: 20%;
}

.project-btn-div p {
  margin-right: 0;
  margin-top: 0;
  margin-left: 0.75em;
  margin-bottom: 0;
}

.project-btn:hover {
  background-color: #f5c91c;
  color: black;
}

.project-btn-div a:hover {
  color: black;
}

.project-view-container {
  width: 80%;
  height: 80%;
  background-color: #545557;
  position: fixed;
  left: 10%;
  top: 1em;
  border-radius: 10px;
  overflow-y: auto;
  padding-bottom: 0.5em;
}

.logo-btn-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.logo-btn-container img {
  width: 50%;
  height: auto;
}

@media (max-width: 490px) {
  .logo-btn-container img {
    width: 100%;
  }
}

.view-btn-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.view-btn-container .view-btn {
  font-size: 0.5em;
  width: 60%;
  height: 30%;
  background-color: #990000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.view-btn a {
  text-decoration: none;
  color: white;
}

.text-content {
  width: 100%;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-content img {
  width: 80%;
  height: auto;
}

.text-container {
  width: 90%;
}

#crawl .text-content p,
h4 {
  font-size: 0.4em;
  text-align: left;
  margin-bottom: 0.25em;
}

#crawl .text-content ul {
  margin-top: 0.2em;
  margin-bottom: 0;
}

#crawl .text-content li {
  font-size: 0.3em;
}

.project-view-container button {
  width: 20%;
  height: 10%;
  background-color: #990000;
  border-radius: 50px;
  border-color: #990000;
  font-size: 0.3em;
  margin: 20px;
  color: white;
}

.project-view-container button:hover {
  color: #990000;
  background-color: white;
  border-color: white;
}

.view-btn-container .view-btn:hover {
  background-color: white;
  border-color: white;
}

.view-btn-container .view-btn a:hover {
  color: #990000;
}

@media (max-width: 685px) {
  .logo-btn-container {
    flex-direction: column;
  }
  .view-btn-container {
    width: 100%;
    flex-direction: row;
    align-items: space-around;
  }
  .view-btn-container .view-btn {
    width: 40%;
    font-size: 0.75em;
  }
}

.contact-subcontainer {
  width: 80%;
  border-width: 10px 10px 10px 10px;
  border-color: #f5c91c;
  border-style: solid;
  border-radius: 10px;
  margin-left: 0.5em;
  margin-bottom: 1em;
}

@media (max-width: 490px) {
  .contact-subcontainer legend svg {
    width: 97%;
    height: auto;
  }
}

.contact-subcontainer .social-media {
  display: flex;
  justify-content: space-around;
}

.contact-subcontainer .social-logo {
  width: 100px;
  height: 100px;
  color: #f5c91c;
}

.social-logo a {
  text-decoration: none;
  color: #f5c91c;
}

@media (max-width: 490px) {
  .contact-subcontainer .social-logo {
    width: 60px;
    height: 60px;
  }
}

.email-container .contact-me-form {
  display: flex;
  flex-direction: column;
}

.contact-me-form input,
.contact-me-form textarea {
  background-color: transparent;
  border-color: #f5c91c;
  font-size: 0.75em;
  color: #f5c91c;
  margin-bottom: 1em;
  width: 90%;
  border-width: 10px 10px 10px 10px;
  border-radius: 10px;
  border-style: solid;
  padding: 0.25em;
}

.email-container .contact-form-btn {
  color: #f5c91c;
  border-color: #f5c91c;
  border-style: solid;
  border-radius: 10px;
  background: transparent;
  border-width: 10px 10px 10px 10px;
  font-size: 0.75em;
  width: 30%;
  height: auto;
}

.contact-form-btn:hover {
  background-color: #f5c91c;
  color: black;
}

/* Navigation button styles */
.cn-button {
  border: none;
  background: none;
  color: #f06060;
  text-align: center;
  font-size: 1.8em;
  padding-top: 1em;
  height: 3.5em;
  width: 3.5em;
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: -1em;
  margin-left: -1em;
  margin-top: -0.5em;
  border-radius: 50%;
  cursor: pointer;
  z-index: 11;
}

/* Navigation menu */
.cn-button:hover,
.cn-button:active,
.cn-button:focus {
  color: #aa1010;
}

.cn-wrapper {
  padding-bottom: 0.5em;
  padding-right: 1.25em;
  font-size: 1em;
  width: 26em;
  height: 26em;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  left: 50%;
  top: -15em;
  margin-left: -12em;
  border-radius: 50%;
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -moz-transform: scale(0.1);
  transform: scale(0.1);
  pointer-events: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.cn-wrapper li {
  position: absolute;
  font-size: 1.5em;
  width: 10em;
  height: 10em;
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  overflow: hidden;
  left: 50%;
  /* top: 50%; */
  margin-top: -1.3em;
  margin-left: -10em;
  -webkit-transition: border 0.3s ease;
  -moz-transition: border 0.3s ease;
  transition: border 0.3s ease;
}

.cn-wrapper li a {
  display: block;
  font-size: 1.18em;
  height: 14.5em;
  width: 14.5em;
  position: absolute;
  bottom: -11em;
  right: -6em;
  border-radius: 50%;
  text-decoration: none;
  color: #fff;
  padding-top: 1.8em;
  text-align: center;
  /* -webkit-transform: skew(-50deg) rotate(-70deg) scale(1);
  -ms-transform: skew(-50deg) rotate(-70deg) scale(1);
  -moz-transform: skew(-50deg) rotate(-70deg) scale(1); */
  /* transform: skew(-30deg) rotate(-60deg) scale(1); */
  /* -webkit-transition: opacity 0.3s, color 0.3s;
  -moz-transition: opacity 0.3s, color 0.3s; */
  transition: opacity 0.3s, color 0.3s;
}

.cn-wrapper li a svg {
  transform: skew(-44deg) rotate(120deg) scale(1);
  width: 50px;
  height: 50px;
  padding-left: 2em;
  fill-opacity: 1;
}

.cn-wrapper li a span {
  font-size: 1.1em;
  opacity: 0.7;
}

/* for a central angle x, the list items must be skewed by 90-x degrees
in our case x=40deg so skew angle is 50deg
items should be rotated by x, minus (sum of angles - 180)2s (for this demo) */

.cn-wrapper li:first-child {
  /* -webkit-transform: rotate(-10deg) skew(50deg);
  -ms-transform: rotate(-10deg) skew(50deg);
  -moz-transform: rotate(-10deg) skew(50deg); */
  transform: rotate(-150deg) skew(50deg);
}

.cn-wrapper li:nth-child(2) {
  /* -webkit-transform: rotate(30deg) skew(50deg);
  -ms-transform: rotate(30deg) skew(50deg);
  -moz-transform: rotate(30deg) skew(50deg); */
  transform: rotate(-110deg) skew(50deg);
}

.cn-wrapper li:nth-child(3) {
  /* -webkit-transform: rotate(70deg) skew(50deg);
  -ms-transform: rotate(70deg) skew(50deg);
  -moz-transform: rotate(70deg) skew(50deg); */
  transform: rotate(-70deg) skew(50deg);
}

.cn-wrapper li:nth-child(odd) a {
  background-color: #4b4b4c;
  /* background-color: hsla(0, 88%, 63%, 1); */
}

.cn-wrapper li:nth-child(even) a {
  background-color: #989898;
  /* background-color: hsla(0, 88%, 65%, 1); */
}

/* hover style */
.csstransforms .cn-wrapper li a:hover {
  background-color: #b31515;
  background-color: hsla(0, 88%, 70%, 1);
}

/* Open menu state */
.opened-nav {
  border-radius: 50%;
  pointer-events: auto;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
